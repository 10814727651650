<script src="../vue.config.js"></script>

<template>

  <div class="w-full flex flex-col gap-10">
    <div class="flex flex-row justify-between">
      <div class="bg-blue-600 p-2 text-white ">Hotpot管理后台</div>
      <el-select v-loading="loadding" v-model="selectedTimezone" placeholder="选择时区" @change="handleTimezoneChange">
        <el-option
            v-for="zone in timezones"
            :key="zone.value"
            :label="zone.label"
            :value="zone.value">
        </el-option>
      </el-select>
    </div>
    <div class="w-full flex flex-col items-center">
      <div class="w-[90%] ">
        <div class="flex flex-row justify-between">
          <el-button v-loading="loadding" @click="showAddDialog=true">添加用户</el-button>
          <div class="flex flex-row gap-3">
            <el-button v-loading="loadding" icon="refresh" @click="initData">刷新</el-button>
            <!--            <el-button icon="sorted">排序</el-button>-->
          </div>
        </div>
        <el-table
            v-loading="loadding"
            class="mt-3"
            :data="paginatedData"
            style="width: 100%"
            @sort-change="handleSortChange"
            border
        >
          <el-table-column
              prop="userId"
              label="id"
              width="60"
              sortable="custom"
              :default-sort="{order: getSortOrder()}"
          >
          </el-table-column>
          <el-table-column
              prop="key"
              label="key"
              width="330"
          >

            <template #default="scope">
              <el-input v-model="scope.row.key" readonly>
                <template #append>
                  <el-button icon="el-icon-document-copy" :disabled="isCancelled(scope.row)"
                             v-clipboard:copy="scope.row.key"
                             v-clipboard:success="copyKey">复制
                  </el-button>
                </template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="regTime"
              label="添加时间"
              width="160"
          >
          </el-table-column>
          <el-table-column
              label="可用性"
              width="405"
          >
            <template #default="scope">
              <div v-if="isCancelled(scope.row)">
                已注销
              </div>
              <el-date-picker
                  v-else
                  v-model="scope.row.availableRange"
                  type="datetimerange"
                  range-separator="-"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  @change="updateDateRange(scope.row)"
                  :clearable="false"
                  style="width: 380px;"
              >
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column
              label="使用次数上限" width="165">
            <template #default="scope">
              <el-input-number  v-model="scope.row.maxUse" @change="handleChangeMaxUse(scope.row)" :disabled="isCancelled(scope.row)" :min="1" :max="1000" :step="1" :precision="0" label="" style="width: 140px;"></el-input-number>

            </template>
          </el-table-column>
          <el-table-column
              prop="used"
              label="已用次数" width="180">
            <template #default="scope">

              <el-input v-model="scope.row.used" readonly>
                <template #append>
                  <el-button icon="el-icon-document-copy" @click="setLoginList(scope.row)">查看
                  </el-button>
                </template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
              label="备注">
            <template #default="scope">
              <el-input v-model="scope.row.note" @focus="handleFouceNote(scope)"
                        @change="handleChangeNote(scope.row)"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="190">

            <template #default="scope">
              <el-button size="mini" @click="getKey(scope.row)" :disabled="isCancelled(scope.row)">获取key</el-button>
              <el-button size="mini" type="danger" icon="delete" :disabled="isCancelled(scope.row)"
                         @click="handleRemoveKey(scope.row)">注销key
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @current-change="handlePageChange"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="totalItems"
            layout="total, prev, pager, next, jumper">
        </el-pagination>
        <el-dialog
            title="添加用户"
            :visible.sync="showAddDialog"
            width="30%">
          <el-form :model="formData" :rules="rules" ref="formRef">
            <el-form-item label="可用开始时间" prop="availableStart">
              <el-date-picker
                  v-model="formData.availableStart"
                  type="datetime"
                  placeholder="选择时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item v-if="formData.availableStart" label="可用结束时间" prop="availableEnd">
              <el-date-picker
                  v-model="formData.availableEnd"
                  type="datetime"
                  :picker-options="endPickerOptions"
                  placeholder="选择时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="使用次数上限" prop="maxUse">
              <el-input-number v-model="formData.maxUse" :min="1" :precision="0" label="最大使用次数"/>
            </el-form-item>
            <el-form-item label="备注" label-width="100">
              <el-input
                  type="textarea"
                  v-model="formData.note">
              </el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
<!--            <el-button @click="showAddDialog = false">取消</el-button>-->
            <el-button type="primary" @click="submitNewData">提交</el-button>
          </span>
        </el-dialog>
        <el-dialog title="登录记录" :visible.sync="showLoginListDialog">
          <el-table :data="loginList" height="400">
            <el-table-column prop="loginTime" label="登录时间"></el-table-column>
            <el-table-column prop="expireTime" label="有效期至"></el-table-column>
            <el-table-column prop="ip" label="登录IP"></el-table-column>

          </el-table>
        </el-dialog>

        <el-dialog
            :visible.sync="confirmDialogVisible"
            title="确认操作"
            @close="confirmDialogVisible = false">
          <span>{{ confirmDialogMessage }}</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="confirmDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="confirmOperation">确认</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {format} from 'date-fns';

export default {
  name: 'App',
  components: {},
  data() {
    return {
      tableData: [],
      paginatedData: [], // 分页后的数据
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      loadding: false,
      showLoginListDialog: false,
      loginList: [],
      showAddDialog: false,
      noteTmp:"",
      maxUseTmp:"",
      originalData: {},
      formData: {
        availableStart: '',
        availableEnd: '',
        maxUse: 2,
        note: ''
      },

      rules: {
        availableStart: [
          {required: true, message: '请选择开始时间', trigger: 'blur'}
        ],
        availableEnd: [
          {required: true, message: '请选择结束时间', trigger: 'blur'}
        ],
        maxUse: [
          {required: true, message: '请输入最大使用次数', trigger: 'blur'},
          {type: 'number', message: '最大使用次数必须为数字值', trigger: 'change'}
        ],
        note: [
          {required: true, message: '请填写备注信息', trigger: 'blur'},
          {min: 3, max: 200, message: '备注长度在 3 到 200 个字符', trigger: 'blur'}
        ]
      },
      selectedTimezone: this.getStoredTimezone(), // 加载已存储的时区
      timezones: [
        { value: "-12", label: '时区 UTC-12' },
        { value: "-11", label: '时区 UTC-11' },
        { value: "-10", label: '时区 UTC-10' },
        { value: "-9", label: '时区 UTC-9' },
        { value: "-8", label: '时区 UTC-8' },
        { value: "-7", label: '时区 UTC-7' },
        { value: "-6", label: '时区 UTC-6' },
        { value: "-5", label: '时区 UTC-5' },
        { value: "-4", label: '时区 UTC-4' },
        { value: "-3", label: '时区 UTC-3' },
        { value: "-2", label: '时区 UTC-2' },
        { value: "-1", label: '时区 UTC-1' },
        { value: "0", label: '时区 UTC' },
        { value: "1", label: '时区 UTC+1' },
        { value: "2", label: '时区 UTC+2' },
        { value: "3", label: '时区 UTC+3' },
        { value: "4", label: '时区 UTC+4' },
        { value: "5", label: '时区 UTC+5' },
        { value: "6", label: '时区 UTC+6' },
        { value: "7", label: '时区 UTC+7' },
        { value: "8", label: '时区 UTC+8' },
        { value: "9", label: '时区 UTC+9' },
        { value: "10", label: '时区 UTC+10' },
        { value: "11", label: '时区 UTC+11' },
        { value: "12", label: '时区 UTC+12' }
      ],

      confirmDialogVisible: false,
      confirmDialogMessage: '',
      pendingOperation: null,
      endPickerOptions: {
        disabledDate: time => {
          return this.formData.availableStart ?
              time.getTime() <= new Date(this.formData.availableStart).getTime() :
              false;
        }
      }
    }

  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.loadding = true
      this.$axios({
        url: "https://api.hotpot.run/admin",
        method: "POST",
        data: {
          "request": "get",
          "timeDifference": this.selectedTimezone
        }
      }).then(res => {
        this.tableData = res.data.map(item => ({
          ...item,
          availableRange: [item.availableStart, item.availableEnd],
          regTime: item.regTime.replaceAll("T", " ")
        }));

        const sortPreference = this.getSortOrder();
        if (sortPreference) {
          this.applySort(sortPreference.prop, sortPreference.order);
        }

        this.totalItems = this.tableData.length;
        this.originalData = JSON.parse(JSON.stringify(this.tableData)); // 深拷贝数据
        this.paginateData();  // 调用分页方法
        this.loadding = false
      })
    },
    copyKey() {
      this.$notify({
        message: 'Copied to clipboard',
        type: 'success',
        duration: 1500
      })
    },
    handleRemoveKey(row) {
      console.log(row)
      this.loadding = true
      this.$axios({
            url: "https://api.hotpot.run/admin",
            method: "POST",
            data: {
              "request": "invalidateKey",
              "userId": row.userId,
            }
          }
      ).then(res => {
        if (res.data.result === "success") {
          this.$notify({
            message: '删除成功',
            type: 'success',
            duration: 1500
          })
          this.initData()
        } else {
          this.$notify({
            message: res.data.information,
            type: "error"
          })
          this.initData()
        }
        // this.loadding = false
      })
    },
    handleChangeMaxUse(row) {

      let tmpV = row.maxUse
      console.log(tmpV)




      this.showConfirmDialog("您确定要更改使用次数上限", () => {

        this.loadding = true
        // for(let key in this.originalData){
        //   if(this.originalData[key].userId === row.userId){
        //     console.log(this.originalData[key])
        //     this.tableData.filter(f=>f.userId===row.userId)[0].maxUse = this.originalData[key].maxUse
        //   }
        // }
        this.$axios({
              url: "https://api.hotpot.run/admin",
              method: "POST",
              data: {
                "request": "modifyMaxUse",
                "userId": row.userId,
                "maxUse": tmpV
              }
            }
        ).then(res => {
          this.loadding = false
          if (res.data.result === "success") {
            this.$notify({
              message: '更改成功',
              type: 'success',
              duration: 1500
            })
          } else {
            this.$notify({
              message: res.data.information,
              type: "error"
            })
            this.initData()
          }
        })
      })

    },

    handleChangeNote(row) {
      this.$nextTick(()=>{
        let tmpNote = row.note
        console.log(this.noteTmp)
        this.tableData.filter(f=>f.userId===row.userId)[0].note=this.noteTmp
        this.showConfirmDialog("您确定要更改备注吗", () => {
          this.loadding = true

          this.$axios({
                url: "https://api.hotpot.run/admin",
                method: "POST",
                data: {
                  "request": "modifyNote",
                  "userId": row.userId,
                  "note": tmpNote
                }
              }
          ).then(res => {
            if (res.data.result === "success") {
              this.$notify({
                message: '更改成功',
                type: 'success',
                duration: 1500

              })
              this.initData()
            } else {
              this.$notify({
                message: res.data.information,
                type: "error"
              })
              this.initData()
            }
          })
        })
      })

    },
    updateDateRange(row) {
      if(!row.availableRange){
        this.initData()
        return
      }
      this.showConfirmDialog("是否确认更改", () => {
        if (row.availableRange && row.availableRange.length === 2) {
          const [start, end] = row.availableRange;
          // 格式化时间为后端接受的格式
          row.availableStart = format(new Date(start), 'yyyy-MM-dd HH:mm:ss');
          row.availableEnd = format(new Date(end), 'yyyy-MM-dd HH:mm:ss');

          // 这里打印出来看一下结果
          console.log('Formatted Start:', row.availableStart);
          console.log('Formatted End:', row.availableEnd);
          this.loadding = true
          this.$axios({
                url: "https://api.hotpot.run/admin",
                method: "POST",
                data: {
                  "request": "modifyTimeRange",
                  "userId": row.userId,
                  "availableStart": row.availableStart,
                  "availableEnd": row.availableEnd,
                  "timeDifference": this.selectedTimezone
                }
              }
          ).then(res => {
            this.loadding = false
            if (res.data.result === "success") {
              this.$notify({
                message: '更改成功',
                type: 'success',
                duration: 1500
              })
            }
          })
          // 调用 API 更新后端数据
          // this.saveDateRange(row);
        }
      })
    },
    submitNewData() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.loadding = true
          this.$axios({
                url: "https://api.hotpot.run/admin",
                method: "POST",
                data: {
                  ...this.formData,
                  request: "add",
                  timeDifference: 8
                }
              }
          ).then(res => {
            this.loadding = false
            if (res.data.result === "success") {
              this.$notify({
                message: '添加成功',
                type: 'success',
                duration: 1500
              })
              // 在这里添加 API 调用逻辑以提交表单数据到后端
              this.showAddDialog = false; // 关闭对话框
              this.initData()
            } else {
              this.$notify({
                message: res.data.information,
                type: "error"
              })

            }
          })
        } else {
          console.log('Validation failed.');
          return false;
        }
      });


      console.log('Form data:', this.formData);

    },
    setLoginList(row) {
      let data = this.tableData.filter(f => f.userId === row.userId)[0]
      this.loginList = data['loginHistory']
      this.loginList = this.loginList.map(f=>{
        return{
          ...f,
          loginTime:f.loginTime.replace("T"," "),
          expireTime:f.expireTime.replace("T"," "),
        }
      })
      this.showLoginListDialog = true
    },
    getKey(row) {
      console.log(row)
      this.loadding = true
      this.$axios({
            url: "https://api.hotpot.run/admin",
            method: "POST",
            data: {
              "request": "getNewKey",
              "userId": row.userId
            }
          }
      ).then(res => {
        if (res.data.key !== "") {
          this.$notify({
            message: '获取成功',
            type: 'success',
            duration: 1500
          })
          row.key = res.data.key
        } else {
          this.$notify({
            message: "获取失败",
            type: "error"
          })
        }
        this.loadding = false
      })
    },
    handleTimezoneChange(newTimezone) {
      localStorage.setItem('selectedTimezone', newTimezone);
      this.initData()
    },
    getStoredTimezone() {
      return localStorage.getItem('selectedTimezone') || "8"; // 从 localStorage 获取时区，如果不存在则返回默认空字符串
    },
    handleSortChange({prop, order}) {
      if (order) {
        this.storeSortPreference(prop, order);
        this.applySort(prop, order);
      }
    },
    applySort(prop, order) {
      if (order === 'ascending') {
        this.tableData.sort((a, b) => a[prop] - b[prop]);
      } else if (order === 'descending') {
        this.tableData.sort((a, b) => b[prop] - a[prop]);
      }
    },
    storeSortPreference(prop, order) {
      const sortPreference = {prop, order};
      localStorage.setItem('sortPreference', JSON.stringify(sortPreference));
    },
    getSortOrder() {
      const sortPreference = localStorage.getItem('sortPreference');
      return sortPreference ? JSON.parse(sortPreference) : null;
    },
    showConfirmDialog(message, operation) {
      this.confirmDialogMessage = message;
      this.pendingOperation = operation;
      this.confirmDialogVisible = true;
    },
    confirmOperation() {
      if (this.pendingOperation) {
        this.pendingOperation();
      }
      this.confirmDialogVisible = false; // 关闭对话框
    },
    isCancelled(row) {
      const start = new Date(row.availableStart);
      const end = new Date(row.availableEnd);
      return start > end; // 如果起始时间大于结束时间，则返回 true
    },
    paginateData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      this.paginatedData = this.tableData.slice(startIndex, startIndex + this.pageSize);
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.paginateData();
    },
    handleFouceNote(scope){
      this.noteTmp = scope.row.note
      console.log(scope.row)
    },
    cancelEdit(row, index) {
      this.confirmDialogVisible=false
      if (row.note !== this.originalData[index].note) {
        this.tableData[index].note = this.originalData[index].note; // 还原到原始数据
      }
    },
  },
  watch: {
    tableData: {
      immediate: true,
      handler() {
        this.paginateData();
      }
    }
  },

}
</script>

<style>

</style>
